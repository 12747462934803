<template>
   <div>
      <div class="iframeContainer i_z100" v-if="iurl">
         <v-btn elevation="2" class="_bt btxiframe" width="5px" height="42px" @click="closePointCloud()"> <v-icon class="">mdi-close</v-icon> </v-btn>
         <v-btn elevation="2" class="_bt bt2iframe" width="5px" height="42px" @click="putTagOnCloud()"> <v-icon class="">mdi-tag-plus-outline</v-icon> </v-btn>
         <v-btn elevation="2" class="_bt btInfo" width="5px" height="42px" :class="{ bluebtn: showhint }" @click="showhint = !showhint"> <v-icon class="">mdi-help</v-icon></v-btn>
         <iframe id="datawrapper-chart-8dfPN" :src="iurl" scrolling="no" frameborder="0" allowtransparency="false" class="iframeclass" name="potreeFrame"></iframe>
         <div v-if="showhint" @click="showhint = false" class="hintLayer">
            <v-img :src="require('../../assets/navigation.png')" class="my-3" contain width="100%" height="100%"/>
         </div>
         <PointcloudTagInf class="finemehere"
            v-show="!showPointCloudForm"
            :pointCloudTag="pointCloudTagItem"
            :itemAbsolutePosition="infPosition"
            @showEditTagForm="showEditTagForm"
             @gototag="gototag"
            />

         <PointcloudTagForm class="finemehere"
            :pointcloudItem="pointCloudTagItem"
            :showPointCloudForm="showPointCloudForm"
            @callPointCloudServiceListBySite="callPointCloudServiceListBySite"
            @getPointCloudTagsList="getPointCloudTagsList"
            @clearPointCloudTagItem="clearPointCloudTagItem"/>
      </div>
   </div>
</template>

<script>
import PointCloudTagsService from '@/service/PointCloudTagService.js';
import PointcloudService from '@/service/PointcloudService';
import PointcloudTagInf from '@/components/PointcloudTagInf.vue';
import PointcloudTagForm from '@/components/PointcloudTagForm.vue';
import PointcloudTag from '@/models/PointcloudTag.js';

export default {
   components: {
      PointcloudTagForm,
      PointcloudTagInf,
   },
   data() {
      return {
         showhint: false,
         createTagMod: false,
         pointCloudTagItem: null,
         selectedSite: null,
         systemDesign: null,
         PointCloudTagsList: null,
         focusSystemTagId: null,
         showPointCloudTagInf: false,
         infPosition: { x: 200, y: 200 },
         showPointCloudForm: false,
         iurl: null,
         pointCloud: null,
      };
   },
   computed: {
      selected_point_cloud_id() {
         return this.$store.state.app.iframeUrl;
      },
   },
   watch: {
      selected_point_cloud_id(id) {
         if (!id) {
            this.iurl = null;
            this.pointCloud = null;
            return;
         }
         const pointCloudService = new PointcloudService();
         pointCloudService.get(id).then((res) => {
            this.pointCloud = res.data.data[0];
            this.selectedSite = this.pointCloud.site_id;
            this.iurl = this.pointCloud.url;
         });
      },
   },
   created() {
      window.addEventListener('message', (event) => {
         if (typeof (event.data.type) === 'string') {
            console.log('got a message',event);
            // TODO: #3 need to check event.origin for security
            switch (event.data.type) {
            case 'createTag':
               this.passDataToTagForm(event.data.data);
               break;
            case 'clickTag':
               this.passClickTag(event.data.data.id);
               break;
            case 'ready':
               this.getPointCloudTagsList();
               break;
            default:
               break;
            }
         }
      });
   },
   methods: {
      gototag(systemTagId) {
         this.$emit('gototag', systemTagId);
      },
      showEditTagForm() {
         console.log('showEditTagForm_pointCloudTagItem', this.pointCloudTagItem);
         this.showPointCloudForm = true;
      },
      passClickTag(tagid) {
         const result = this.PointCloudTagsList.find((obj) => obj.id === tagid);
         this.showPointCloudForm = false;
         this.pointCloudTagItem = result;
      },
      clearPointCloudTagItem() {
         console.log('clearPointCloudTagItem_pointCloudTagItem', this.pointCloudTagItem);
         this.pointCloudTagItem = null;
         this.showPointCloudForm = false;
      },
      getPointCloudTagsList() {
         if (this.PointCloudTagsList) {
            this.clearAllTagsOnCloud();
         }

         const pointCloudTagsService = new PointCloudTagsService();
         if (!this.selected_point_cloud_id) {
            console.log('Error, cant find point cloud id');
            return;
         }
         pointCloudTagsService.list(this.selected_point_cloud_id).then((res) => {
            if (res) {
               this.PointCloudTagsList = res.data;
               this.PointCloudTagsList.forEach((element) => {
                  this.drawTagOnCloud(element);
               });
            }
         });
      },
      clearAllTagsOnCloud() {
         window.potreeFrame.postMessage({ type: 'deleteAllTags' }, '*');
      },
      drawTagOnCloud(element) {
         console.log('drawTagOnCloud', element);
         window.potreeFrame.postMessage({ type: 'drawTag', data: element }, '*');
      },
      putTagOnCloud() {
         window.potreeFrame.postMessage({ type: 'putTag' }, '*');
         this.createTagMod = true;
      },
      updateData(data) {
         this.pointCloudTagItem = data;
         this.callPointCloudServiceListBySite(this.selectedSite);
      },
      closePointCloud() {
         if (this.showhint) {
            this.showhint = false;
         } else {
            this.$store.dispatch('storeIframeUrl', null);
         }
      },
      callPointCloudServiceListBySite(site) {
         const siteInUse = site || this.selectedSite;
         this.loading = true;
         const pointcloudService = new PointcloudService(this);
         pointcloudService.list(siteInUse.id).then((list) => {
            console.log('index.vue: src/components/PointCloudViewer.vue: callPointCloudServiceListBySite: list', list);
            if (list) {
               this.pointcloudsList = list.data;
            }
            this.loading = false;
         });
      },
      passDataToTagForm(data) {
         this.pointCloudTagItem = new PointcloudTag({
            id: null,
            site_id: this.selectedSite,
            point_cloud_id: this.pointCloud.id,
            icon_pos: data.icon_pos,
            cam_pos: data.cam_pos,
         });
         this.showPointCloudForm = true;
      },
   },
};
</script>

<style scoped>
.hintLayer {
   background-color: #000000a1;
   width: 100%;
   height: 100%;
   display: block;
   top: 0;
   left: 0;
   position: absolute;
}
.finemehere{
   z-index: 99999999999999;
}
</style>
