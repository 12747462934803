<template>
   <div v-if="pointCloudTagItem">
      <v-card :style="'position: absolute; left:'+(positem.x)+'px; top:'+positem.y+'px;'" elevation="2" class="sysInfoCard  rounded-lg" v-click-outside="onClickOutsideInfWindow">
         <v-toolbar
            color="info"
            dark
            dense
            flat

         >
            <v-toolbar-title class="text-body-2">
               {{pointCloudTagItem.system_tag.tag_name}}
            </v-toolbar-title>
         </v-toolbar>
         <v-card-text>
            {{pointCloudTagItem.system_tag.short_desc}}
         </v-card-text>
         <v-divider></v-divider>
         <v-card-subtitle>
            <v-icon small> mdi-paperclip </v-icon> Files:
         </v-card-subtitle>
         <v-card-text>
            <div class="tx" v-for="(file, index) in pointCloudTagItem.system_tag.system_tag_files" :key="`${index}file`">
               <a :href=file.file target="_blank">{{file.name}}</a>
            </div>
         </v-card-text>
         <v-divider></v-divider>
         <v-card-actions class="d-flex justify-space-around">
            <v-btn color="info" class="mb-4 " @click="editTag">
               <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
            <v-btn color="info" v-if="hasPointCloud" class="mb-4" @click="openSVG">
               <v-icon color="white">mdi-eye-arrow-right</v-icon>
            </v-btn>
         </v-card-actions>
      </v-card>
   </div>
</template>

<script>

export default {
   props: {
      itematagitem: {
         type: Object,
         default: null,
      },
      itemAbsolutePosition: {
         type: Object,
         default: null,
      },
      pointCloudTag: {
         type: Object,
         default: null,
      },
   },
   data: () => ({
      positem: null,
      hasPointCloud: false,
      outsideEventIsActive: false,
      pointCloudTagItem: null,
   }),
   methods: {
      editTag() {
         this.$emit('showEditTagForm');
      },
      onClickOutsideInfWindow() {
         if (this.outsideEventIsActive) {
            this.pointCloudTagItem = null;
         }
      },
      openSVG() {
         this.$emit('gototag', this.pointCloudTagItem);
      },
   },
   watch: {
      pointCloudTag: function (val) {
         if (val) {
            this.outsideEventIsActive = false;
            this.pointCloudTagItem = val;
            if (val.point_cloud_tag !== null) {
               this.hasPointCloud = true;
            } else {
               this.hasPointCloud = false;
            }

            setTimeout(() => {
               this.outsideEventIsActive = true;
            }, 300);
         } else {
            this.pointCloudTagItem = null;
            this.outsideEventIsActive = false;
         }
         this.positem = this.itemAbsolutePosition || null;
      },
   },
};
</script>

<style>
.v-toolbar__content, .v-toolbar__extension {
   padding-left: 15px !important;
}
</style>
