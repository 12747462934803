<template>
   <v-select :items="siteslist" @change="selectChange" v-model="selfSelected" label="Choose site">
      <template v-slot:selection="{ item }" class="">
         <span>{{ item.title }}</span>
      </template>
      <template v-slot:item="{ item, attrs, on }">
         <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-content>
               <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
         </v-list-item>
      </template>
   </v-select>
</template>

<script>
import SiteService from '@/service/SiteService.js';

export default {
   data() {
      return {
         siteslist: [],
         selflist: null,
         selfSelected: null,
      };
   },
   mounted() {
      this.callSiteServiceList();
   },
   methods: {
      selectChange() {
         this.$emit('update:selectedSite', this.selfSelected);
      },
      callSiteServiceList(page = 1) {
         this.page = page;
         this.loading = true;
         const siteService = new SiteService(this);
         siteService.list(page, this.sort, this.order).then((list) => {
            if (list) {
               this.siteslist = list.data;
               this.$emit('update:siteslist', this.siteslist);
            }
            this.loading = false;
         });
      },
   },
};
</script>
