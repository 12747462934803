import ParentService from '@/service/ParentService.js';

export default class SiteService extends ParentService {
   /**
    *
    * @param {int} id
    * @returns {object} result
    */
   get(id) {
      const url = `${super.baseUrl}site/${parseInt(id)}`;
      return super.customGet(url);
   }

   list(page, sort, order, searchDto = {}) {
      const url = `${super.baseUrl}site`;
      searchDto.page = page;
      searchDto.sort = sort;
      searchDto.order = order;
      return super.customGet(url, searchDto);
   }

   export() {
      const url = `${super.baseUrl}site/export`;
      return super.customGetDownload(url);
   }

   add(dto) {
      const url = `${super.baseUrl}site`;
      return super.customPost(url, dto);
   }

   update(id, dto) {
      const url = `${super.baseUrl}site/${id}`;
      return super.customPut(url, dto);
   }
}
