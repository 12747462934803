<template>
   <v-slide-x-reverse-transition>
      <div
         v-if="selfItem&&showPointCloudForm"
         class="sideForm pa-5 fill-height rounded-l-lg elevation-8"
         :class="{ sticktop: scrolled }"
      >
         <v-row>
            <v-col class="d-flex justify-end">
               <v-btn outlined small fab @click="closeForm">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
            </v-col>
         </v-row>
         <v-form ref="PointCloudTagForm">
            <v-card-title>Point cloud tag</v-card-title>
            <v-select
               v-model="selectedSystem"
               @change="getTags"
               :items="systemsList"
               item-text="title"
               item-value="id"
               label="Choose system"></v-select>

            <v-select
               v-if="systemTagsList"
               v-model="systemTagModel"
               :items="systemTagsList"
               label="Choose Category">
               <template v-slot:selection="{ item }" class="">
                  <v-img
                     :src="getWhiteSVGimageURL(item.icon)"
                     max-height="25"
                     max-width="25"
                     contain
                     class="mr-2"
                  ></v-img>
                  <span>{{ item.tag_name }}</span>
               </template>

               <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                     <v-list-item-icon>
                        <v-img
                           :src="getWhiteSVGimageURL(item.icon)"
                           max-height="35"
                           max-width="35"
                           contain
                        ></v-img>
                     </v-list-item-icon>
                     <v-list-item-content>
                        <v-list-item-title v-text="item.tag_name"></v-list-item-title>
                     </v-list-item-content>
                  </v-list-item>
               </template>
            </v-select>
            <br />
            <v-btn color="info" class="mr-4" @click="submit">
               Submit
            </v-btn>
            <v-btn @click="clear"> clear </v-btn>
            <v-btn @click="onsvg"> On system </v-btn>
         </v-form>
      </div>
   </v-slide-x-reverse-transition>
</template>
<script>
import SystemService from '@/service/SystemService.js';
import PointCloudTagsService from '@/service/PointCloudTagService.js';
import SystemTagService from '@/service/SystemTagService.js';
import PointcloudTag from '@/models/PointcloudTag.js';

export default {
   props: {
      pointcloudItem: {
         type: Object,
         default: null,
      },
      showPointCloudForm: {
         type: Boolean,
         default: false,
      },
   },
   watch: {
      pointcloudItem: function (val) {
         if (val != null) {
            this.selfItem = (val);
            this.callSystemServiceListBySite(this.selfItem.site_id);
         } else {
            this.selfItem = null;
         }
         if (val) { this.fileDat = val.file_thumb; }
      },
   },
   
   data: () => ({
      fileDat: null,
      tmpimg: null,
      selfItem: null,
      valid: false,
      email: '',
      expDate: '',
      usersCount: '',
      logo: '',
      address: '',
      name: '',
      nameRules: [
         (v) => !!v || 'This field is required',
         (v) => v.length <= 50 || 'Name must be less than 20 characters',
      ],
      emailRules: [
         (v) => !!v || 'This field is required',
         (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      usersCountRules: [
         (v) => !!v || 'This field is required',
         (v) => v >= 1 || 'This field must ne a number',
      ],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      selectedSystem: null,
      systemsList: [],
      recs: null,
      selectedRec: null,
      systemTagsList: null,
      systemTagModel: null,
      scrolled: false,
   }),
   mounted: function () {
      window.addEventListener('scroll', this.scrollListener);
   },
   methods: {
      getWhiteSVGimageURL(val) {
         return `${process.env.VUE_APP_API}/svg/ffffff/${val}.svg`;
      },
      submit() {
         const data = {
            point_cloud_id: this.selfItem.point_cloud_id,
            site_id: this.selfItem.site_id,
            icon_pos: this.selfItem.icon_pos,
            cam_pos: this.selfItem.cam_pos,
            system_tag_id: this.systemTagModel.id,
         };
         const pointCloudTagsService = new PointCloudTagsService();
         pointCloudTagsService.store(data).then((res) => {
            if (res) {
               // message saved seccessfully
               this.$refs.PointCloudTagForm.reset();
               this.$emit('getPointCloudTagsList');
               this.$emit('clearPointCloudTagItem');
            }
         });
      },
      getTags() {
         if (!this.selectedSystem) return;
         const systemTagService = new SystemTagService();
         systemTagService.getAllUnlinked(this.selectedSystem).then((res) => {
            if (res) {
               this.systemTagsList = res.data;
               if (this.selfItem.system_tag.category_id) {
                  this.systemTagModel = this.selfItem.system_tag;
               }
            }
         });
      },
      callSystemServiceListBySite(siteId) {
         this.loading = true;
         const systemService = new SystemService(this);
         systemService.list(siteId).then((list) => {
            if (list) {
               this.systemsList = list.data;
               if (this.selfItem.system_tag.system_id) {
                  this.selectedSystem = this.selfItem.system_tag.system_id;
                  this.getTags();
               }
            }
            this.loading = false;
         });
      },
      loadTagsList() {

      },
      addFiles() {
         this.readers = new FileReader();
         this.readers.onloadend = () => {
            const fileData = this.readers.result;
            this.fileDat = fileData;
            this.selfItem.file_svg = fileData;
         };
         this.readers.readAsDataURL(this.tmpimg);
      },
      clear() {
         // this.$refs.form.reset();
         const pointCloudTagsService = new PointCloudTagsService();
         pointCloudTagsService.delete(this.selfItem.id).then((res) => {
            // this.getTags();
            this.$emit('getPointCloudTagsList');
         });
      },
      onsvg() {
         const newTag = {
            cam_pos: this.pointcloudItem.cam_pos,
            icon_pos: this.pointcloudItem.icon_pos,
            point_cloud_id: this.pointcloudItem.point_cloud_id,
            site_id: this.pointcloudItem.site_id,
         };
         localStorage.setItem('newTag', JSON.stringify(newTag));
         // this.$emit('editSvg');
         this.$router.push({ path: '/systems', query: { site_id: this.pointcloudItem.site_id } });
      },
      closeForm() {
         // this.selfItem = null;
         // this.fileDat = null;
         // this.tmpimg = null;
         this.$emit('getPointCloudTagsList');
         this.$emit('clearPointCloudTagItem');
      },
      scrollListener: function () {
         if (window.scrollY > 65) { this.scrolled = true; } else { this.scrolled = false; }
      },
   },
   
   beforeDestroy: function () {
      window.removeEventListener('scroll', this.scrollListener);
   },
};
</script>

<style>
.v-menu__content--fixed {
z-index: 999999990 !important;
}
</style>
